import React, { useCallback, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { amountFormatter } from 'helpers/number_helper';
import { useAppSelector } from 'slices/hooks';
import { getClaimById, updateClaim } from 'service/claim';
import { ClaimStatus } from 'types/api/claim';
import { CustomButton } from 'Components/UI/CustomButton';
import { toast } from 'react-toastify';

const InvoiceApproval = () => {
    document.title = "Reclamos de facturas | Partarentals";
    const navigate = useNavigate()
    const { _id } = useParams()
    const { user } = useAppSelector(state => state.Profile)
    const { data: invoiceClaim } = useHandleApiCall(getClaimById, {
        recallArguments: [_id ?? '']
    });
    const handleUpdateInvoiceCall = useHandleApiCall(updateClaim)

    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [modal_declined, setmodal_declined] = useState<boolean>(false);
    function decline_invoice() {
        setmodal_declined(!modal_declined);
    }

    const handleClaimStatusManagement = useCallback(async (status: ClaimStatus) => {
        try {
            await handleUpdateInvoiceCall.refetch(_id ?? "", { status })
            if (status === ClaimStatus.APPROVED) {
                toast.success("Reclamo aprobado")
            } else {
                toast.success("Reclamo rechazado")
            }
            setmodal_center(false)
            navigate("/invoice-claim")
        } catch (error) {
            toast.error("Error al actualizar el estado del reclamo")
        }
    }, [_id, handleUpdateInvoiceCall, navigate])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Reclamos de facturas" pageTitle="Pages" />
                    {
                        invoiceClaim?.status === "pending" &&
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col lg={6} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h4 className="card-title mb-0 flex-grow-1 fw-bold">Su arrendatario  ha solicitado un reclamo de pago de factura el {new Date(invoiceClaim?.creationDate ?? 0).toLocaleDateString()}</h4>
                                            </Col>
                                            <Col lg={6} className="d-flex align-items-center mb-0 justify-content-end">
                                                <button type="button" className="d-flex align-items-center material-shadow-none btn btn-success mr-2" onClick={() => tog_center()}>
                                                    Aprobar reclamo {" "} <i className="ms-2 ri-check-line iconAction"></i>
                                                </button>
                                                <button type="button" className="d-flex align-items-center material-shadow-none btn btn-danger" onClick={() => decline_invoice()}>
                                                    Rechazar reclamo <i className="ms-2 ri-close-line iconAction"></i>
                                                </button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <div className="live-preview">

                                        {/*First Row*/}
                                        <Row className='mb-3'>
                                            <Col lg={12}>
                                                <h5 className='fw-semibold'>Reclamar pago</h5>
                                                <Row>
                                                    <Col lg={3}>
                                                        <div>
                                                            <Label>
                                                                Contrato asociado
                                                            </Label>
                                                            <div>
                                                                <Label className='fw-semibold'>
                                                                    {invoiceClaim?.contract._id}
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <div>
                                                            <Label>
                                                                Monto de la factura
                                                            </Label>
                                                            <div>
                                                                <Label className='fw-semibold'>
                                                                    {amountFormatter(invoiceClaim?.amount ?? 0)}
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label>
                                                                Detalle del gasto realizado
                                                            </Label>
                                                            <div>
                                                                <p className='fw-semibold' dangerouslySetInnerHTML={{
                                                                    __html: invoiceClaim?.description ?? ''
                                                                }}>

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        {/*Second Row*/}
                                        <Row className='mb-3'>
                                            <Col lg={3}>
                                                <h5 className='fw-semibold'>Comprobante de factura</h5>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className='d-flex align-items-center'>
                                                            <a href={invoiceClaim?.invoiceProof} download className='text-success text-decoration-underline'>
                                                                Descargar factura <i className='ms-1 bx bxs-download text-success'></i>
                                                            </a>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={3}>
                                                <h5 className='fw-semibold'>Imagenes de evidencia</h5>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className='d-flex flex-column align-items-flex-start'>
                                                            {
                                                                invoiceClaim?.evidences.map(el => {
                                                                    return <div key={el}>
                                                                        <a href={el} download className='text-success text-decoration-underline'>
                                                                            Descargar imagenes <i className='ms-1 bx bxs-download text-success'></i>
                                                                        </a>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-error-circle text-warning" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Desea continuar?</h4>
                        <p className="text-muted mb-4">Una vez aprobado se deducirá <span className="fw-bold">{amountFormatter(invoiceClaim?.amount ?? 0)}</span> de su estado de cuenta</p>
                        <div className="hstack gap-2 justify-content-center">
                            <CustomButton loading={handleUpdateInvoiceCall.loading} color="light" onClick={() => setmodal_center(false)}>Atras</CustomButton>
                            <CustomButton loading={handleUpdateInvoiceCall.loading} color="success" onClick={() => handleClaimStatusManagement(ClaimStatus.APPROVED)}>Aprobar</CustomButton>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_declined}
                toggle={() => {
                    decline_invoice();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
                        <form action="">
                            <p className="text-muted mb-4">Una vez rechazado el estado de cuenta debera ponerse en contacto con su inquilino</p>
                            <div className="hstack gap-2 justify-content-center">
                                <CustomButton loading={handleUpdateInvoiceCall.loading} color="light" onClick={() => setmodal_declined(false)}>Cancelar</CustomButton>
                                <CustomButton loading={handleUpdateInvoiceCall.loading} color="danger" onClick={() => handleClaimStatusManagement(ClaimStatus.REJECTED)}>Rechazar</CustomButton>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment >
    );
};

export default InvoiceApproval;