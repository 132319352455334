import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { amountFormatter } from 'helpers/number_helper';
import { getClaims } from 'service/claim';
import { ClaimStatus } from 'types/api/claim';

const badgeText = {
    [ClaimStatus.APPROVED]: "APROBADO",
    [ClaimStatus.PENDING]: "PENDIENTE POR APROBAR",
    [ClaimStatus.RESOLVED]: "RESUELTO",
    [ClaimStatus.REJECTED]: "RECHAZADO",
}

const badgeColor = {
    [ClaimStatus.APPROVED]: "secondary",  //"#0AB39C",
    [ClaimStatus.PENDING]: "warning",  //"#F1A347",
    [ClaimStatus.RESOLVED]: "success",  //"#3577F1",
    [ClaimStatus.REJECTED]: "danger",  //"#EA4335",
}

const InvoiceClaimStateBadge = ({ status }: { status: `${ClaimStatus}` }) => {
    return <h5><Badge color={badgeColor[status]} className="badge-soft-primary font-size-12">{badgeText[status]}</Badge></h5>
}

const InvoiceClaim = () => {
    const { user } = useAppSelector(state => state.Profile)
    const navigate = useNavigate()
    document.title = "Reclamos de facturas | Partarentals";
    const [offset, setOffset] = useState(0);
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [pageSize, setPageSize] = useState<number>(0)
    const [canNextPage, setCanNextPage] = useState<boolean>(true)
    const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)

    const handleGetClaimsCall = useHandleApiCall(getClaims)

    const pageOptions = useMemo(() => {
        return pageSize > 0 ? new Array(Math.ceil((pageSize / limit))).fill(null).map((el, i) => i) : []
    }, [limit, pageSize])

    const [pageIndex, setPageIndex] = useState<number>(0)
    const nextPage = () => {
        if (page < pageOptions.length - 1) {
            setPage(page + 1)
        }
    }
    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const gotoPage = (page: number) => {
        setPage(page)
    }

    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [modal_declined, setmodal_declined] = useState<boolean>(false);
    function decline_payment() {
        setmodal_declined(!modal_declined);
    }

    useEffect(() => {
        handleGetClaimsCall.refetch({
            skip: 0,
            limit: 10,
            offset: 0
        })
    }, [handleGetClaimsCall.refetch, user?.roles[0]])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Reclamos de facturas" pageTitle="Pages" />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <Col lg={6} className="d-flex align-items-center mb-0 justify-content-start">
                                        <h4 className="card-title mb-0 flex-grow-1">Listado</h4>
                                    </Col>

                                    {/*MOSTRAR CUANDO SEA INQUILINO*/}

                                    {user?.roles[0] === Role.Tenant && <Col lg={6} className="d-flex align-items-center mb-0 justify-content-end">
                                        <button
                                            type="button"
                                            className="d-flex align-items-center material-shadow-none btn btn-success mr-2"
                                            onClick={() => navigate("/generate-claim-invoice")}>
                                            Nuevo pago de factura
                                        </button>
                                    </Col>}


                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={12}>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12}>
                                            <div className="live-preview mb-2">
                                                <div className="table-responsive">
                                                    <Table className="align-middle table-nowrap mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th scope="col">Contrato asociado</th>
                                                                <th scope="col">Fecha de creación</th>
                                                                <th scope="col">Monto</th>
                                                                {
                                                                    user?.roles[0] === Role.Owner &&
                                                                    <th scope="col">Inquilino</th>
                                                                }
                                                                {
                                                                    user?.roles[0] === Role.Tenant &&
                                                                    <th scope="col">Propietario</th>
                                                                }
                                                                <th scope="col">Detalle del gasto realizado</th>
                                                                <th scope="col">Estatus</th>
                                                                <th scope="col" className='text-center'>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                (handleGetClaimsCall?.data?.data ?? [])?.map((item) => {
                                                                    return <tr key={item._id}>
                                                                        <td><Link to={`/contracts-details/${item.contract._id}`} className='enlace-tabla'>{item.contract._id}</Link></td>
                                                                        <td>{new Date(item.creationDate).toLocaleDateString()}</td>
                                                                        <td>{amountFormatter(item.amount)}</td>
                                                                        {
                                                                            user?.roles[0] === Role.Owner &&
                                                                            <td>{`${item.contract.tenant.name} ${item.contract.tenant.surname}`}</td>
                                                                        }
                                                                        {
                                                                            user?.roles[0] === Role.Tenant &&
                                                                            <td>{`${item.contract.owner.name} ${item.contract.owner.surname}`}</td>
                                                                        }
                                                                        <td><p dangerouslySetInnerHTML={{
                                                                            __html: item.description
                                                                        }}></p></td>
                                                                        <td><InvoiceClaimStateBadge status={item.status} /></td>
                                                                        <td className='text-center'>
                                                                            {/* MOSTRAR CUANDO SEA ROL PROPIETARIO */}
                                                                            {
                                                                                user?.roles[0] === Role.Owner &&
                                                                                <button type="button" className="material-shadow-none btn btn-success"
                                                                                    style={{ minWidth: 176 }}
                                                                                    onClick={() => navigate(`/invoice-approval/${item._id}`)}>
                                                                                    {
                                                                                        item.status === "pending" ? "Ver detalle y aprobar" : "Ver detalle"
                                                                                    }
                                                                                </button>
                                                                            }


                                                                            {/* MOSTRAR CUANDO SEA ROL INQUILINO */}
                                                                            {
                                                                                user?.roles[0] === Role.Tenant &&
                                                                                <div className="d-flex justify-contents-start">
                                                                                    <UncontrolledDropdown className="card-header-dropdown" direction='start'>
                                                                                        <DropdownToggle tag="a" className="btn btn-link text-secondary p-2 py-1 text-decoration-none fs-15 bg-light" role="button">
                                                                                            <span className="fs-18"><i className="mdi mdi-dots-horizontal btn-secondary"></i></span>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu className="dropdown-menu-end">
                                                                                            <DropdownItem>Editar solicitud</DropdownItem>
                                                                                            <DropdownItem>Ver detalle</DropdownItem>
                                                                                            <DropdownItem>Solicitar soporte {"(proximamente)"}</DropdownItem>
                                                                                            <DropdownItem>Reclamar facturas</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='d-flex align-items-center mb-2'>
                                        <Col sm className='text-start'>
                                            <div className="">Mostrando<span className="fw-semibold ms-1">1</span> de <span className="fw-semibold">10</span> de <span className="fw-semibold">14</span> registros
                                            </div>
                                        </Col>

                                        <Col sm={"auto"} className='text-end'>
                                            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                                <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                                                    <Link to="#" className="page-link" onClick={previousPage}>Previous</Link>
                                                </li>
                                                {pageOptions.map((item: any, key: any) => (
                                                    <React.Fragment key={key}>
                                                        <li className="page-item">
                                                            <Link to="#" className={page === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
                                                        </li>
                                                    </React.Fragment>
                                                ))}
                                                <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                                                    <Link to="#" className="page-link" onClick={nextPage}>Next</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='position-relative d-flex justify-content-end'>
                            <button type='button' className='btn btn-danger d-flex align-items-center justify-content-center' style={{ height: 37, width: 37 }}><i className='bx bx-up-arrow-alt' style={{ fontSize: 18 }}></i></button>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
                        <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                            <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_declined}
                toggle={() => {
                    decline_payment();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
                        <form action="">
                            <div className="mb-3 text-start">
                                <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
                                <select className="form-select mb-3" aria-label="Default select example">
                                    <option value="pago_incompleto">Pago incompleto</option>
                                </select>
                            </div>
                            <div className="mb-3 text-start">
                                <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea>
                            </div>
                            <div className="hstack gap-2 justify-content-center">
                                <Button color="light" onClick={() => setmodal_declined(false)}>Cancelar</Button>
                                <Button color="danger" onClick={() => console.log("Funciona")}>Rechazar</Button>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default InvoiceClaim;