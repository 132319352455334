import { cl } from "@fullcalendar/core/internal-common";
import { useHandleApiCall } from "hooks/useHandleApiCall";
import { useProfileUtils } from "hooks/useProfileUtils";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GetOwnerPaymentMethods } from "service/paymentMethods";
import { useAppSelector } from "slices/hooks";
import { Role } from "types";
enum MenuItems {
    Dashboard = 'Dashboard',
    FavoriteProperties = 'Propiedades favoritas',
    Users = 'Usuarios',
    Properties = 'Properties',
    Contracts = 'Contratos',
    Payments = 'Pagos',
    Agents = 'Agents',
}

const RoleEquivalent = {
    [Role.Tenant]: "Arrendatario",
    [Role.Owner]: "Arrendador",
    [Role.Agent]: "Arrendador",
    [Role.Admin]: "Admin",

}


const Navdata = () => {
    const history = useNavigate();
    const { user } = useAppSelector(state => state.Profile)
    const { isProfileComplete } = useProfileUtils()
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isApps, setIsApps] = useState<boolean>(false);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isPages, setIsPages] = useState<boolean>(false);
    const [isBaseUi, setIsBaseUi] = useState<boolean>(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState<boolean>(false);
    const [isForms, setIsForms] = useState<boolean>(false);
    const [isTables, setIsTables] = useState<boolean>(false);
    const [isCharts, setIsCharts] = useState<boolean>(false);
    const [isIcons, setIsIcons] = useState<boolean>(false);
    const [isMaps, setIsMaps] = useState<boolean>(false);
    const [isMultiLevel, setIsMultiLevel] = useState<boolean>(false);

    //Parta Rentals
    const [isUsers, setIsUsers] = useState<boolean>(false);
    const [isAgents, setIsAgents] = useState<boolean>(false);

    const [isProperties, setIsProperties] = useState<boolean>(false);
    const [isContract, setIsContract] = useState<boolean>(false);
    const [isPayments, setIsPayments] = useState<boolean>(false);
    const [isFavoriteProperties, setIsFavoriteProperties] = useState<boolean>(false);


    // Apps
    const [isCalendar, setCalendar] = useState<boolean>(false);
    const [isEmail, setEmail] = useState<boolean>(false);
    const [isSubEmail, setSubEmail] = useState<boolean>(false);
    const [isEcommerce, setIsEcommerce] = useState<boolean>(false);
    const [isProjects, setIsProjects] = useState<boolean>(false);
    const [isTasks, setIsTasks] = useState<boolean>(false);
    const [isCRM, setIsCRM] = useState<boolean>(false);
    const [isCrypto, setIsCrypto] = useState<boolean>(false);
    const [isInvoices, setIsInvoices] = useState<boolean>(false);
    const [isSupportTickets, setIsSupportTickets] = useState<boolean>(false);
    const [isNFTMarketplace, setIsNFTMarketplace] = useState<boolean>(false);
    const [isJobs, setIsJobs] = useState<boolean>(false);
    const [isJobList, setIsJobList] = useState<boolean>(false);
    const [isCandidateList, setIsCandidateList] = useState<boolean>(false);


    // Authentication
    const [isSignIn, setIsSignIn] = useState<boolean>(false);
    const [isSignUp, setIsSignUp] = useState<boolean>(false);
    const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState<boolean>(false);
    const [isLockScreen, setIsLockScreen] = useState<boolean>(false);
    const [isLogout, setIsLogout] = useState<boolean>(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState<boolean>(false);
    const [isVerification, setIsVerification] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    // Pages
    const [isProfile, setIsProfile] = useState<boolean>(false);
    const [isLanding, setIsLanding] = useState<boolean>(false);


    // Charts
    const [isApex, setIsApex] = useState<boolean>(false);

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);
    const [isLevel2, setIsLevel2] = useState<boolean>(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement;
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== MenuItems.Dashboard) {
            setIsDashboard(false);
        }
        if (iscurrentState !== MenuItems.FavoriteProperties) {
            setIsFavoriteProperties(false);
        }
        if (iscurrentState !== MenuItems.Contracts) {
            setIsContract(false);
        }
        if (iscurrentState !== MenuItems.Agents) {
            setIsAgents(false);
        }
        if (iscurrentState !== MenuItems.Payments) {
            setIsPayments(false);
        }
        if (iscurrentState !== MenuItems.Properties) {
            setIsProperties(false);
        }
        if (iscurrentState !== MenuItems.Properties) {
            setIsUsers(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [history, iscurrentState]);

    const handleGetPaymentMethods = useHandleApiCall(GetOwnerPaymentMethods, {
        recallArguments: [{
            ownerId: user?._id ?? ""
        }],
        ignoreError: true
    })

    const menuItems: any = [
        {
            label: `Menu ${user?.roles[0] ? RoleEquivalent[user?.roles[0]] : ""}`,
            isHeader: true,
        },
        /* user?.roles && [Role.Owner, Role.Agent, Role.Admin].includes(user?.roles[0]) && {
            id: "dashboard",
            label: "Dashboards",
            icon: "ri-dashboard-line",
            link: "/#",
            stateVariables: isDashboard,
            click: function (e: any) {
                e.preventDefault();
                setIsDashboard(prev => !prev);
                setIscurrentState(MenuItems.Dashboard);
                updateIconSidebar(e);
            },
        }, */
        user?.roles && [Role.Tenant].includes(user?.roles[0]) && {
            id: "favoriteproperties",
            label: "Propiedades favoritas",
            icon: "ri-dashboard-2-line",
            link: "/#",
            stateVariables: isFavoriteProperties,
            click: function (e: any) {
                e.preventDefault();
                setIsFavoriteProperties(prev => !prev);
                setIscurrentState(MenuItems.FavoriteProperties);
                updateIconSidebar(e);
            },
        },
        user?.roles && [Role.Admin].includes(user?.roles[0]) && {
            id: "users",
            label: "Usuarios",
            icon: " ri-hotel-line",
            link: "/pages-property-lists",
            stateVariables: isUsers,
            click: function (e: any) {
                e.preventDefault();
                setIsUsers(prev => !prev);
                setIscurrentState(MenuItems.Users);
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "usersdetails",
                    label: "Visualizar un usuario",
                    link: "/pages-user-register",
                    parentId: "users",
                },
                {
                    id: "usersform",
                    label: "Crear un usuario",
                    link: "/pages-users-form",
                    parentId: "users",
                },
            ]
        },
        user?.roles && [Role.Owner, Role.Agent, Role.Admin].includes(user?.roles[0]) && {
            id: "properties",
            label: "Propiedades",
            icon: " ri-hotel-line",
            link: "/pages-property-lists",
            stateVariables: isProperties,
            click: function (e: any) {
                e.preventDefault();
                setIsProperties(prev => !prev);
                setIscurrentState(MenuItems.Properties);
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "propertiesform",
                    label: "Subir una propiedad",
                    link: "/pages-property-register",
                    parentId: "properties",
                },
                {
                    id: "propertiesdetails",
                    label: "Visualizar propiedades",
                    link: "/pages-property-lists",
                    parentId: "properties",
                },
            ].filter(el => el)
        },
        user?.roles && [Role.Owner, Role.Agent, Role.Admin, Role.Tenant].includes(user?.roles[0]) && {
            id: "contract",
            label: "Contratos",
            icon: "ri-file-paper-2-line",
            link: "/contracts-register",
            stateVariables: isContract,
            click: function (e: any) {
                e.preventDefault();
                setIsContract(prev => !prev);
                setIscurrentState(MenuItems.Contracts);
                updateIconSidebar(e);
            },
            subItems: [
                [Role.Owner, Role.Agent, Role.Admin].includes(user.roles[0]) && {
                    id: "contractform",
                    label: "Registrar contrato",
                    link: (() => {
                        if (user.roles[0] === Role.Agent) {
                            return "/contracts-register"
                        }
                        if (isProfileComplete) {
                            return "/contracts-register"
                        } else if (!isProfileComplete) {
                            return "/pages-profile-settings"
                        }
                    })(),
                    parentId: "contract",
                    click: async () => {
                        if (user.roles[0] === Role.Agent) {
                            return
                        }
                        try {
                            if (!isProfileComplete) {
                                return toast.error("Completa tu perfil para poder registrar un contrato")
                            }
                            const paymentMethodsData = await handleGetPaymentMethods.refetch({ ownerId: user?._id })
                            if (paymentMethodsData?.length === 0) {
                                history("/payment-methods")
                                return toast.error("Registra un método de pago para poder registrar un contrato")
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }
                },
                {
                    id: "contractactive",
                    label: "Contratos activos",
                    link: "/active-contracts-preview",
                    parentId: "contract",
                },
                {
                    id: "contract-inactive",
                    label: "Contratos inactivos",
                    link: "/finished-contracts-preview",
                    parentId: "contract",
                },
                {
                    id: "contractoffers",
                    label: "Ofertas de contratos",
                    link: "/contracts-offers-preview",
                    parentId: "contract",
                },
            ].filter(el => el)
        },
        user?.roles && [Role.Owner, Role.Agent, Role.Admin, Role.Tenant].includes(user?.roles[0]) && {
            id: "payments",
            label: "Pagos",
            icon: " ri-secure-payment-line",
            link: "/pages-payments-lists",
            stateVariables: isPayments,
            click: function (e: any) {
                e.preventDefault();
                setIsPayments(prev => !prev);
                setIscurrentState(MenuItems.Payments);
                updateIconSidebar(e);
            },

            subItems: [
                {
                    id: "paymentshistory",
                    label: "Historial de pagos",
                    link: "/payment-history",
                    parentId: "payments",
                },
                user?.roles && [Role.Owner, Role.Admin].includes(user?.roles[0]) ? {
                    id: "paymentsmethods",
                    label: "Métodos de pago",
                    link: "/payment-methods",
                    parentId: "payments",
                } : null,
                {
                    id: "accountState",
                    label: "Estado de cuenta",
                    link: "/account-state",
                    parentId: "payments",
                },
                {
                    id: "invoiceClaim",
                    label: "Reclamo de factura",
                    link: "/invoice-claim",
                    parentId: "payments",
                },
            ].filter(el => el)
        },
        {
            id: "agents",
            label: "Agentes",
            icon: "ri-customer-service-2-fill",
            link: "/pages-agents",
            click: function (e: any) {
                e.preventDefault();
                setIsAgents((prev) => !prev);
                setIscurrentState(MenuItems.Agents);
                updateIconSidebar(e);
            },
        },
    ].filter(el => el);
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;