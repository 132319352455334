//AUTHENTICATION
export const POST_REGISTER = "/authentication/register";
export const POST_LOGIN = "/authentication/login";
export const POST_LOGIN_GOOGLE = "/authentication/login/google";
export const POST_SEND_RESET_PASSWORD = "/authentication/send-reset-password"
export const POST_RESET_PASSWORD = "/authentication/reset-password"
export const VERIFY_EMAIL = "/authentication/verify-email"
export const DECODE_TOKEN = "/authentication/decode-token"
export const CHANGE_PASSWORD = "/authentication/change-password"


//AGENTS
export const GET_AGENTS = "/agents";
export const CREATE_AGENTS = "/agents";
export const DELETE_AGENTS = "/agents";
export const UPDATE_AGENTS = "/agents";

//COUNTS DASHBOARD
export const GET_COUNT_AGENTS = "/agents/date";
export const GET_COUNT_PROPERTIES = "/properties/date";
export const GET_COUNT_RENT_COMPLETED = "/properties/completeRentals";

// LAST PROPERTIES DASHBOARD
export const GET_LAST_PROPERTIES = "/properties/dashboard/properties";
export const GET_ALL_PROPERTIES = "/properties";
export const GET_ALL_PROPERTIES_FILTERS_V1 = "/properties/filters";
export const CREATE_PROPERTY = "/properties";
export const DELETE_PROPERTY = "/properties";
export const UPDATE_PROPERTY = "/properties";

// UBICATIONS
export const GET_PROVINCE = "/ubications/provincias";
export const GET_CANTON = "/ubications/cantones";
export const GET_DISTRICT = "/ubications/distritos";

// USERS

export const GET_USERS = "/users"
export const POST_USER = "/users"
export const GET_UNIQUE_USER = "/users/{id}"
export const PUT_USER = "/users/{id}"
export const DELETE_USER = "/users/{id}"
export const GET_USER_BY_DNI = "/users/dni/{dni}"
// RATING

export const GET_RATING = "rating"
export const POST_RATING = "rating"
export const SEND_RATING = "email/send"

// CONTRACTS
export const GET_CONTRACTS = "/contracts"
export const POST_CONTRACTS = "/contracts"
export const POST_RENEW_CONTRACTS = "/contracts/{id}/renew"
export const GET_CONTRACT = "/contracts/{id}"
export const PATH_CONTRACT = "/contracts/{id}"
export const DELETE_CONTRACT = "/contracts/{id}"
export const END_CONTRACT_APPROVED = "/contracts/end-contract/{id}"

// CURRENCIES
export const GET_CURRENCIES = "/currencies"
// FAVORITE PROPERTIES
export const SET_FAVORITE_PROPERTY = "/favorite-properties/{id}"
export const DELETE_FAVORITE_PROPERTY = "/favorite-properties/{id}"
export const GET_FAVORITE_PROPERTY = "/favorite-properties"

// PAYMENTS
export const GET_PAYMENTS = "/payments"
export const GET_PAYMENT = "/payments/{id}"
export const POST_PAYMENTS = "/payments"
export const PATH_PAYMENT_STATUS = "/payments/{id}/status"
export const DELETE_PAYMENT = "/payments/{id}"

export const POST_PAYMENTS_ACCOUNT_STATEMENT = "/payments/account-statement"
export const PATH_PAYMENT_ACCOUNT_STATEMENT = "/payments/account-statement-status/{id}"

// PAYMENT METHODS
export const CREATE_PAYMENT_METHODS = "/user-payments-methods"
export const GET_OWNER_PAYMENT_METHODS = "/user-payments-methods/{ownerId}"
export const GET_PAYMENT_METHOD = "/user-payments-methods/method/{id}"
export const UPDATE_PAYMENT_METHOD = "/user-payments-methods/{id}"
export const DELETE_PAYMENT_METHOD = "/user-payments-methods/{id}"

// ACCOUNT STATEMENT

export const CREATE_ACCOUNT_STATEMENT = "/accounts-statement"
export const GET_ACCOUNT_STATEMENTS = "/accounts-statement/paginated"
export const GET_ACCOUNT_STATEMENTS_TOTAL_AMOUNT = "/accounts-statement/total-amount"
export const GET_ACCOUNT_STATEMENT_AMOUNT = "/accounts-statement/total-amount"
export const GET_USER_ACCOUNT_STATEMENT = "/accounts-statement/user"
export const GET_ACCOUNT_STATEMENT = "/accounts-statement/{id}"
export const UPDATE_ACCOUNT_STATEMENT = "/accounts-statement/{id}"
export const RUN_CRON_MORATORIES = "/accounts-statement/cron-moratorias"
export const RUN_CRON_MENSUALIDADES = "/accounts-statement/cron-mensualidades"

// CLAIM

export const CREATE_CLAIM = "/claims"
export const GET_CLAIMS = "/claims"
export const GET_CLAIM = "/claims/{id}"
export const UPDATE_CLAIM = "/claims/{id}"


export const GET_USER_CLAIM = "/claims/user/claims"
export const GET_RESOLVED_CLAIM = "/claims/resolved/claims"
