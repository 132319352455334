import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { GetPayments, UpdateAccountStatementPaymentStatus, UpdatePaymentStatus } from 'service/payments';
import { amountFormatter } from 'helpers/number_helper';
import { PaymentMethodMap } from 'types/api/paymentMethod';
import moment from 'moment';
import { IPayment, PaymentStatus, PaymentStatusMap, RejectionState, RejectionStateMap } from 'types/api/payments';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useAppSelector } from 'slices/hooks';

const PaymentTagConfig = {
  [PaymentStatus.PENDING]: {
    className: "badge bg-warning"
  },
  [PaymentStatus.COMPLETED]: {
    className: "badge bg-success"
  },
  [PaymentStatus.REJECTED]: {
    className: "badge bg-danger"
  },
}

const PaymentHistory = () => {
  document.title = "Historial de pago | Partarentals";
  const { user } = useAppSelector((state) => state.Profile)
  const handleGetPayments = useHandleApiCall(GetPayments, {
    recallArguments: [{}],
    initState: []
  });

  const [rejectedPaymentForm, setRejectedPaymentForm] = useState<{
    rejectionReason: string,
    rejectionState: RejectionState
  }>({ rejectionReason: "", rejectionState: RejectionState.INCOMPLETE_PAYMENT })

  const [selectedPayment, setSelectedPayment] = useState<IPayment | null>(null)
  const handleUpdatePayment = useHandleApiCall(UpdatePaymentStatus)
  const handleUpdateAccountStatementPayment = useHandleApiCall(UpdateAccountStatementPaymentStatus)

  const [approve_center, set_modal_approve] = useState<boolean>(false);
  function tog_approve_modal() {
    set_modal_approve(!approve_center);
  }

  const [modal_declined, set_modal_declined] = useState<boolean>(false);
  function decline_payment() {
    set_modal_declined(!modal_declined);
  }

  const handlePaymentStatus = useCallback(async (payment: IPayment, paymentStatus: PaymentStatus) => {
    try {
      const paymentId = payment._id
      const selectedHandleUpdate = payment.accountStatements?.length
        ? handleUpdateAccountStatementPayment
        : handleUpdatePayment
      if (!paymentId) {
        toast.error("Se debe seleccionar un pago para poder actualizar su estado")
        return
      }
      if (paymentStatus === PaymentStatus.COMPLETED)
        await selectedHandleUpdate.refetch(paymentId, { status: paymentStatus })
      else if (paymentStatus === PaymentStatus.REJECTED)
        await selectedHandleUpdate.refetch(paymentId, { status: paymentStatus, rejectionReason: rejectedPaymentForm.rejectionReason, rejectionState: rejectedPaymentForm.rejectionState })
      toast.success("Estado del pago actualizado correctamente")
      await handleGetPayments.refetch({})
      set_modal_approve(false)
      set_modal_declined(false)
    } catch (error) {
      toast.error("Error al actualizar el estado del pago")
    }
  }, [handleGetPayments, handleUpdateAccountStatementPayment, handleUpdatePayment, rejectedPaymentForm.rejectionReason, rejectedPaymentForm.rejectionState])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Historial de pagos" pageTitle="Pages" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Últimos pagos recibidos</h4>
                </CardHeader>
                <CardBody>
                  <div className="live-preview">
                    <div className="table-responsive">
                      <Table className="align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            {/* <th scope="col" style={{ "width": "42px" }}>
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck"></Label>
                              </div>
                            </th> */}
                            <th scope="col">Contrato asociado</th>
                            <th scope="col">Monto</th>
                            <th scope="col">Método de pago</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Estatus</th>
                            <th scope="col">Motivo del rechazo</th>
                            <th scope="col">Comprobante</th>
                            <th scope="col">Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (handleGetPayments?.data ?? []).map((payment, index) => (
                              <tr key={payment._id}>
                                {/* <th scope="row">
                                  <div className="form-check">
                                    <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                                    <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                                  </div>
                                </th> */}
                                <td><Link to={`/contracts-details/${payment.contract._id}`} className='enlace-tabla'>{payment.contract._id}</Link></td>
                                <td>{amountFormatter(payment.amount)}</td>
                                <td>{PaymentMethodMap[payment.paymentMethod]}</td>
                                <td>{moment(payment.paymentDate).format("DD/MM/YYYY")}</td>
                                <td><h5><span className={PaymentTagConfig[payment.status].className}>{PaymentStatusMap[payment.status]}</span></h5></td>
                                <td>
                                  {payment.rejectionState && <>
                                    <span className="form-label" id={`p_${index}`}>{payment.rejectionState && RejectionStateMap[payment.rejectionState]} <i className='ri-error-warning-line'></i></span>
                                    <UncontrolledTooltip placement="top" target={`p_${index}`}><span>{payment.rejectionReason}</span></UncontrolledTooltip>
                                  </>}
                                </td>
                                <td>{payment.proof && <Link to={payment.proof} download={true} className='enlace-tabla'>Ver comprobante</Link>}</td>
                                <td>
                                  {
                                    //@ts-ignore
                                    payment.status === PaymentStatus.PENDING && (payment.contract.agent === user?._id || payment.contract.owner === user?._id) && <>
                                      <button type="button" className="btn-icon material-shadow-none btn btn-success" onClick={() => {
                                        setSelectedPayment(payment)
                                        tog_approve_modal()
                                      }}>
                                        <i className="ri-check-line iconAction"></i>
                                      </button>
                                      <button type="button" className="btn-icon material-shadow-none btn btn-danger ms-3" onClick={() => {
                                        setSelectedPayment(payment)
                                        decline_payment()
                                      }}>
                                        <i className="ri-close-line iconAction"></i>
                                      </button>
                                    </>
                                  }
                                </td>
                              </tr>))
                          }
                          {/* <tr>
                            <th scope="row">
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                              </div>
                            </th>
                            <td><Link to="#w-medium" className='enlace-tabla'>#VZ2110</Link></td>
                            <td>$1.200,50</td>
                            <td>Zelle</td>
                            <td>10/10/2024</td>
                            <td><h5><span className="badge bg-warning">PENDIENTE APROBACIÓN</span></h5></td>
                            <td></td>
                            <td><Link to="#w-medium" className='enlace-tabla'>Ver comprobante</Link></td>
                            <td>
                              <button type="button" className="btn-icon material-shadow-none btn btn-success" onClick={() => tog_center()}>
                                <i className="ri-check-line iconAction"></i>
                              </button>
                              <button type="button" className="btn-icon material-shadow-none btn btn-danger ms-3" onClick={() => decline_payment()}>
                                <i className="ri-close-line iconAction"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                              </div>
                            </th>
                            <td><Link to="#w-medium" className='enlace-tabla'>#VZ2110</Link></td>
                            <td>$1.200,50</td>
                            <td>Zelle</td>
                            <td>10/10/2024</td>
                            <td><h5><span className="badge bg-success">APROBADO</span></h5></td>
                            <td></td>
                            <td><Link to="#w-medium" className='enlace-tabla'>Ver comprobante</Link></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                              </div>
                            </th>
                            <td><Link to="#w-medium" className='enlace-tabla'>#VZ2110</Link></td>
                            <td>$1.200,50</td>
                            <td>Zelle</td>
                            <td>10/10/2024</td>
                            <td><h5><span className="badge bg-danger">RECHAZADO</span></h5></td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                            <td><Link to="#w-medium" className='enlace-tabla'>Ver comprobante</Link></td>
                            <td></td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Vertically Centered */}
      <Modal
        isOpen={approve_center}
        toggle={() => {
          tog_approve_modal();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
            <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
            <div className="hstack gap-2 justify-content-center">
              <Button color="light" onClick={() => set_modal_approve(false)}>Cancelar</Button>
              <Button color="success" onClick={() => selectedPayment && handlePaymentStatus(selectedPayment, PaymentStatus.COMPLETED)}>Aprobar</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Vertically Centered */}
      <Modal
        isOpen={modal_declined}
        toggle={() => {
          decline_payment();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-4">
          <i className="ri-spam-fill text-danger" style={{ fontSize: "60px" }}></i>
          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de rechazar este pago?</h4>
            <form action="">
              <div className="mb-3 text-start">
                <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
                <Select
                  className="mb-0"
                  value={{ value: rejectedPaymentForm.rejectionReason, label: RejectionStateMap[rejectedPaymentForm.rejectionState] }}
                  onChange={(selectCountry: any) => {
                    setRejectedPaymentForm({ ...rejectedPaymentForm, rejectionState: selectCountry.value })
                  }}
                  options={[
                    { value: RejectionState.INCOMPLETE_PAYMENT, label: RejectionStateMap[RejectionState.INCOMPLETE_PAYMENT] },
                    { value: RejectionState.NO_PAYMENT_RECEIVED, label: RejectionStateMap[RejectionState.NO_PAYMENT_RECEIVED] },
                    { value: RejectionState.OTHER, label: RejectionStateMap[RejectionState.OTHER] },
                  ]}
                  id="country-select"
                ></Select>

              </div>
              <div className="mb-3 text-start">
                <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3} onChange={
                  (e) => setRejectedPaymentForm({ ...rejectedPaymentForm, rejectionReason: e.target.value })
                }></textarea>
              </div>
              <div className="hstack gap-2 justify-content-center">
                <Button color="light" onClick={() => set_modal_declined(false)}>Cancelar</Button>
                <Button color="danger" onClick={() => selectedPayment && handlePaymentStatus(selectedPayment, PaymentStatus.REJECTED)}>Rechazar</Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment >
  );
};

export default PaymentHistory;