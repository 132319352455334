import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, List, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../../Components/Common/BreadCrumb';

// Import React FilePond
import { FilePond } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { useFormik } from 'formik';
import { FilePondFile } from 'filepond';
import * as Yup from "yup";


import MaestroIcon from '../../../../../assets/images/icons/maestro-icon.png'
import MastercardIcon from '../../../../../assets/images/icons/mastercard-icon.png'
import VisaIcon from '../../../../../assets/images/icons/visa-icon.png'
import { useHandleApiCall } from 'hooks/useHandleApiCall';
import { GetContract } from 'service/contracts';
import { GetOwnerPaymentMethods } from 'service/paymentMethods';
import { PaymentMethodMap, PaymentMethodType } from 'types/api/paymentMethod';
import Select from 'react-select';
import { CreatePayment } from 'service/payments';
import { ICreatePayment, PaymentStatus } from 'types/api/payments';
import FlatPicker from "react-flatpickr";
import { toast } from 'react-toastify';
import moment from 'moment';



const formInitValue: ICreatePayment = {
  amount: 0,
  contract: "",
  description: "",
  from: "",
  to: "",
  paymentMethod: PaymentMethodType.BANK_TRANSFER,
  proof: new File([], ""),
  status: PaymentStatus.PENDING,
  paymentDate: new Date()
}

interface PaymentTemplateProps {
  amount: number;
  contract: string;
  onPaymentSubmit: (data: ICreatePayment) => void;
}

const PaymentTemplate = (v: PaymentTemplateProps) => {
  document.title = "Pago directo al arrendador | Partarentals";
  const navigate = useNavigate()
  const [files, setFiles] = useState<File[]>([]);
  const [filesPreview, setFilesPreview] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<any>();
  const handleGetContract = useHandleApiCall(GetContract, {
    recallArguments: [v.contract ?? ""]
  })

  const [modal_center, setmodal_center] = useState<boolean>(false);

  const handlePaymentValidation = useFormik<ICreatePayment>({
    enableReinitialize: true,
    initialValues: formInitValue,
    onSubmit: () => handleCreatePayment(),
    validationSchema: Yup.object<ICreatePayment>({
      amount: Yup.number().required("Este campo es requerido"),
      contract: Yup.string().required("Este campo es requerido"),
      description: Yup.string().required("Este campo es requerido"),
      from: Yup.string().required("Este campo es requerido"),
      to: Yup.string().required("Este campo es requerido"),
      paymentMethod: Yup.string().required("Este campo es requerido"),
      status: Yup.string().required("Este campo es requerido"),
    }),
  })

  const handleOwnerPaymentMethods = useHandleApiCall(GetOwnerPaymentMethods, {
    initState: [],
    recallArguments: [{
      ownerId: handleGetContract?.data?.owner?._id ?? ""
    }],
    ignoreError: true,
    onSuccess: (data) => {
      if (data[0].type)
        handlePaymentValidation.setFieldValue('paymentMethod', data[0].type)
    }
  })
  function tog_success_payment() {
    setmodal_center(!modal_center);
  }

  const handleAcceptedFiles = useCallback((newFiles: FilePondFile[]) => {
    if (newFiles.length === files.length) return
    Promise.all(newFiles.map(el => el.file.arrayBuffer())).then((buffers) => {
      setSelectedFiles(buffers.map((buffer, index) => {
        return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
      }))

      setFiles(buffers.map((buffer, index) => {
        return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
      }))
      setPreviewImages(buffers.map(el => URL.createObjectURL(new Blob([el]))));
    })
  }, [files.length])
  useEffect(() => {
    function array_equal(arr1: string[], arr2: string[]) {
      if ((Array.isArray(arr1) && Array.isArray(arr2)) === false) return false;
      // @ts-ignore
      return JSON.stringify([...new Set(arr1.flat().sort())]) === JSON.stringify([...new Set(arr2.flat().sort())]);
    }

    if (!array_equal(files.map(el => el.name), filesPreview.map(el => el.name))) {
      setFilesPreview(files.map(el => el));
    }
  }, [files, filesPreview])

  const handleReorderFiles = useCallback(async (newFiles: FilePondFile[]) => {
    let buffers = await Promise.all(newFiles.map(el => el.file.arrayBuffer()))

    setSelectedFiles(buffers.map((buffer, index) => {
      return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
    }))
    setFiles(buffers.map((buffer, index) => {
      return new File([buffer], newFiles[index].filename, { type: newFiles[index].fileType })
    }))
    setPreviewImages(buffers.map(el => URL.createObjectURL(new Blob([el]))));
  }, [])

  const handleCreatePayment: () => Promise<void> = useCallback(async (): Promise<void> => {
    try {
      if (files.length === 0) {
        toast.error("Debe subir un comprobante de pago")
        return
      }
      const data = {
        ...handlePaymentValidation.values,
        proof: files[0],
        contract: handleGetContract.data?._id ?? "",
        from: handleGetContract.data?.tenant._id ?? "",
        to: handleGetContract.data?.owner._id ?? "",
        status: PaymentStatus.PENDING
      }
      v.onPaymentSubmit(data)
    } catch (error) {
      toast.error("Error al crear el pago")
    }
  }, [files, handleGetContract.data?._id, handleGetContract.data?.owner._id, handleGetContract.data?.tenant._id, handlePaymentValidation.values, v])


  useEffect(() => {
    handlePaymentValidation.setValues(prev => ({
      ...prev,
      from: handleGetContract.data?.tenant._id ?? "",
      to: handleGetContract.data?.owner._id ?? "",
      contract: handleGetContract.data?._id ?? "",
      amount: v.amount ?? 0
    }))
  }, [handleGetContract.data])



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Pago directo al arrendador" pageTitle="Pages" marginBottom={false} />


          <Row className='mt-1'>
            <Col xs={12}>
              <Card>
                <CardHeader className="align-items-center d-flex justify-content-between">
                  <Col>
                    <h4 className="card-title mb-0 flex-grow-1">Paga con pago seguro</h4>
                  </Col>
                  <Col className='position-relative d-flex justify-content-end'>
                    <Col xs={1} className='text-end'>
                      <img height={20} width={20} src={MaestroIcon} alt="#" />
                    </Col>
                    <Col xs={1} className='text-center'>
                      <img height={20} width={20} src={MastercardIcon} alt="#" />
                    </Col>
                    <Col xs={1} className='text-start'>
                      <img height={20} width={20} src={VisaIcon} alt="#" />
                    </Col>
                  </Col>
                </CardHeader>
                <CardBody>
                  <div className="live-preview">
                    <div className="table-responsive">
                      <p>PartaRentals funciona como intermediario para procesar los pagos de alquiler de manera segura. El dinero es recibido por PartaRentals y luego depositado directamente en la cuenta bancaria del arrendador. Este método proporciona una mayor confianza y seguridad tanto para arrendadores como arrendatarios.</p>
                      <Button className='btn btn-success' onClick={tog_success_payment}>¡Pagar con tarjeta de crédito ahora!</Button>

                      {/* <Table className="align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ "width": "42px" }}>
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck"></Label>
                              </div>
                            </th>
                            <th scope="col">Contrato asociado</th>
                            <th scope="col">Monto</th>
                            <th scope="col">Método de pago</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Estatus</th>
                            <th scope="col">Motivo del rechazo</th>
                            <th scope="col">Comprobante</th>
                            <th scope="col">Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                              </div>
                            </th>
                            <td><Link to="#w-medium" className='enlace-tabla'>#VZ2110</Link></td>
                            <td>$1.200,50</td>
                            <td>Zelle</td>
                            <td>10/10/2024</td>
                            <td><h5><span className="badge bg-warning">PENDIENTE APROBACIÓN</span></h5></td>
                            <td></td>
                            <td><Link to="#w-medium" className='enlace-tabla'>Ver comprobante</Link></td>
                            <td>
                              <button type="button" className="btn-icon material-shadow-none btn btn-success" onClick={() => tog_center()}>
                                <i className="ri-check-line iconAction"></i>
                              </button>
                              <button type="button" className="btn-icon material-shadow-none btn btn-danger ms-3" onClick={() => decline_payment()}>
                                <i className="ri-close-line iconAction"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                              </div>
                            </th>
                            <td><Link to="#w-medium" className='enlace-tabla'>#VZ2110</Link></td>
                            <td>$1.200,50</td>
                            <td>Zelle</td>
                            <td>10/10/2024</td>
                            <td><h5><span className="badge bg-success">APROBADO</span></h5></td>
                            <td></td>
                            <td><Link to="#w-medium" className='enlace-tabla'>Ver comprobante</Link></td>
                            <td></td>
                          </tr>
                          <tr>
                            <th scope="row">
                              <div className="form-check">
                                <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
                                <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                              </div>
                            </th>
                            <td><Link to="#w-medium" className='enlace-tabla'>#VZ2110</Link></td>
                            <td>$1.200,50</td>
                            <td>Zelle</td>
                            <td>10/10/2024</td>
                            <td><h5><span className="badge bg-danger">RECHAZADO</span></h5></td>
                            <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                            <td><Link to="#w-medium" className='enlace-tabla'>Ver comprobante</Link></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table> */}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={3}>
              <Card>
                <CardBody>
                  {(() => {
                    const paymentMethods = handleOwnerPaymentMethods?.data?.find(el => el.type === PaymentMethodType.BANK_TRANSFER)
                    if (!paymentMethods) return null
                    return <>
                      <Col xs={12}>
                        <div >
                          <div className="p-1 live-preview">
                            <div className="table-responsive">
                              <h4 className='fw-bold fs-16'>Transferencia bancaria:</h4>
                              <p className='fs-12 mb-1'>Realiza tu pago a través de transferencia bancaria, estos deben ser enviados a la siguiente cuenta:</p>
                              <div>
                                <List className="fs-12 mb-0">
                                  <li className='mb-0'><span className='fw-bold'>Titular de la cuenta:</span> {paymentMethods.details.accountName}</li>
                                  <li className='mb-0'><span className='fw-bold'>Banco:</span> {paymentMethods.details.bankName}</li>
                                  <li className='mb-0'><span className='fw-bold'>Número de cuenta:</span> {paymentMethods.details.accountNumber}</li>
                                  <li className='mb-0'><span className='fw-bold'>ABA Routing Number:</span> {paymentMethods.details.swiftCode}</li>
                                  <li>Cuenta en dólares americanos</li>
                                </List>
                              </div>

                            </div>
                          </div>
                        </div>
                      </Col>
                      <hr style={{
                        marginRight: "8px",
                        marginLeft: "8px"
                      }} />
                    </>
                  })()}
                  {
                    (() => {
                      const paymentMethods = handleOwnerPaymentMethods?.data?.find(el => el.type === PaymentMethodType.PAYPAL)
                      if (!paymentMethods) return null
                      return <>
                        <Col xs={12}>
                          <div>
                            <div className="p-1 live-preview">
                              <div className="table-responsive">
                                <h4 className='fw-bold fs-16'>Pago electrónico:</h4>
                                <p className='fs-12 mb-1'>Realiza tu pago a través de los siguientes métodos de pago electrónico:</p>
                                <div className='fw-bold'>
                                  <p className='mb-1 fs-12'>Paypal: {paymentMethods.details.email}</p>
                                  {/* <p className='mb-1 fs-12'>Binance: 123456789</p>
                                <p className='mb-1 fs-12'>Skrill: deazevedoalexander@gmail.com</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <hr style={{
                          marginRight: "8px",
                          marginLeft: "8px"
                        }} />
                      </>
                    })()
                  }
                  {
                    (() => {
                      const paymentMethods = handleOwnerPaymentMethods?.data?.find(el => el.type === PaymentMethodType.SINPE)
                      if (!paymentMethods) return null
                      return <Col xs={12}>
                        <div >
                          <div className="p-1 live-preview">
                            <div className="table-responsive">
                              <h4 className='fw-bold fs-16'>Transferencia SINPE MOVIL:</h4>
                              <p className='fs-12 mb-2'>Pagos a través de transferencia SINPE MOVIL deben ser enviados a la siguiente cuenta:</p>
                              <div>
                                <List className="fs-12">
                                  <li><span className='fw-bold'>Titular de la cuenta:</span> {paymentMethods.details.accountName}</li>
                                  <li><span className='fw-bold'>Teléfono:</span> {paymentMethods.details.phoneNumber}</li>
                                </List>
                              </div>

                            </div>
                          </div>
                        </div>

                      </Col>
                    })()
                  }
                </CardBody>
              </Card>
            </Col>
            <Col lg={9}>
              <Form onSubmit={handlePaymentValidation.handleSubmit}>
                <Row className='mt-2'>
                  <Col xs={12}>
                    <Card>
                      <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Reportar pago</h4>
                      </CardHeader>

                      <CardBody>
                        <Row>
                          <Col lg={6}>
                            <div className='mb-2'>
                              <Label htmlFor="paymentDate" className="form-label">Fecha del pago</Label>

                              <FlatPicker
                                name='paymentDate'
                                id='paymentDate'
                                className="form-control"
                                options={{
                                  dateFormat: "d M, Y",
                                  maxDate: new Date().setHours(23, 59, 59, 999),
                                }}
                                onChange={(date: any) => {
                                  handlePaymentValidation.setFieldValue("paymentDate", date[0].toISOString())
                                }}
                                value={handlePaymentValidation.values.paymentDate}
                              />

                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className='mb-2'>
                              <Label htmlFor="exampleInputdate" className="form-label">Método de pago</Label>
                              <Select
                                options={(handleOwnerPaymentMethods.data ?? []).map(el => ({
                                  label: PaymentMethodMap[el.type],
                                  value: el.type
                                }))}
                                onChange={(el: any) => handlePaymentValidation.setFieldValue("paymentMethod", el?.value)}
                                value={(handleOwnerPaymentMethods.data ?? []).map(el => ({
                                  label: PaymentMethodMap[el.type],
                                  value: el.type
                                })).find(el => el.value === handlePaymentValidation.values.paymentMethod)}
                                placeHolder="Seleccione un método de pago"
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className='mb-4'>
                              <Label htmlFor="amount" className="form-label">Monto pagado</Label>
                              <Input
                                type="number"
                                className="form-control"
                                name="amount"
                                id="amount"
                                aria-label="Amount (to the nearest dollar)"
                                onChange={handlePaymentValidation.handleChange}
                                value={handlePaymentValidation.values.amount}
                                disabled={true}
                              />
                            </div>
                          </Col>

                        </Row>

                        <Row>
                          <Col lg={12}>
                            <p>Subir comprobante de pago</p>
                          </Col>
                        </Row>
                        <Row className='mt-1'>
                          <Col lg={12}>
                            <FilePond
                              files={filesPreview}
                              onupdatefiles={handleAcceptedFiles}
                              allowMultiple={true}
                              onreorderfiles={handleReorderFiles}
                              maxFiles={1}
                              name="files"
                              className="filepond filepond-input-multiple "
                              allowReorder={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} className='d-flex justify-content-end'>
                            <Button className='btn btn-success' onClick={handleCreatePayment}>
                              Enviar
                            </Button>
                          </Col>
                        </Row>
                        <div className="live-preview">
                          <div className="table-responsive">

                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='position-relative d-flex justify-content-end'>
                    <button type='button' className='btn btn-danger d-flex align-items-center justify-content-center' style={{ height: 37, width: 37 }}><i className='bx bx-up-arrow-alt' style={{ fontSize: 18 }}></i></button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container >
      </div >
    </React.Fragment >
  );
};

export default PaymentTemplate;